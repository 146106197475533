<template>
  <div>
    <h2>News</h2>

    <el-button
      v-if="isAdmin"
      type="primary"
      @click="$router.push({ name: 'announcements-add' })"
      >공지 추가</el-button
    >

    <div v-for="announcement in announcements" :key="announcement.id">
      <h3>
        {{ announcement.title }} -
        {{ new Date(announcement.createdAt).toLocaleDateString() }}
      </h3>
      <div v-html="announcement.content"></div>
      <el-button
        v-if="isAdmin"
        @click="
          $router.push({
            name: 'announcements-edit',
            params: { id: announcement.id },
          })
        "
      >
        수정
      </el-button>
      <el-button
        v-if="isAdmin"
        type="danger"
        @click="deleteAnnouncement(announcement)"
      >
        삭제
      </el-button>

      <el-divider></el-divider>
    </div>
  </div>
</template>

<script>
import AnnouncementService from "../services/AnnouncementService";

export default {
  name: "AnnouncementList",
  data() {
    return {
      announcements: [],
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    isAdmin() {
      if (this.currentUser && this.currentUser["roles"]) {
        return this.currentUser["roles"].includes("ROLE_ADMIN");
      }

      return false;
    },
  },
  methods: {
    async retrieveAnnouncements() {
      try {
        const response = await AnnouncementService.getAll();
        this.announcements = response.data;
        console.log(response.data);
      } catch (e) {
        console.log(e);
      }
    },
    async deleteAnnouncement(announcement) {
      try {
        await this.$confirm(
          `정말로 공지 (${announcement.title})를 삭제하시겠습니까?`
        );
        await AnnouncementService.delete(announcement.id);

        this.$message.success("공지가 삭제되었습니다.");
        this.retrieveAnnouncements();
      } catch (e) {
        console.log(e);
      }
    },
  },
  mounted() {
    this.retrieveAnnouncements();
  },
};
</script>

<style>
</style>